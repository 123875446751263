.errors-autocad {
  color: #c60303;
}
button.autocad {
  color: #fff;
  font-size: 16px;
  background: #0167ce;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}
hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}
a.auto {
  font-size: 16;
  font-weight: bold;
  color: #0167ce;
  text-decoration: none;
}
